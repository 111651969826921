<template>
  <div class="matchList-content_overlay">
    <div class="matchList-content_wrapper">
      <div class="matchList-filter_wrapper">
        <div class="matchList-filter_title">{{ $t("SELECT_SIDE") }}</div>
        <div class="matchList-filter_buttons">
          <div
            class="nav-pill"
            @click="$emit('selectSide')"
            :class="{ active: thisOdds.isSameLeague }"
          >
            {{ $t("SAME_SIDE") }}
          </div>
        </div>
      </div>

      <div class="matchList-filter_wrapper">
        <div class="matchList-filter_title">{{ $t("SELECT_TYPE") }}</div>
        <div class="matchList-filter_buttons">
          <div
            v-for="l in leagueOption"
            :key="l.leagueId"
            class="nav-pill"
            @click="$emit('selectLeague', l.leagueId)"
            :class="{ active: thisOdds.leagueList.includes(l.leagueId) }"
          >
            {{ l.leagueName }}
          </div>
        </div>
      </div>

      <div class="matchList-filter_wrapper" v-if="listOption">
        <div class="matchList-filter_title">{{ $t("LATEST_X_GAMES") }}</div>
        <div class="matchList-filter_buttons">
          <div
            v-for="l in listOption"
            :key="l"
            class="nav-pill"
            :class="{ active: lengthSelected === l }"
            @click="$emit('selectList', l)"
          >
            {{ l }}
          </div>
        </div>
      </div>

      <div class="matchList-filter_wrapper">
        <div class="matchList-filter_title">{{ $t("CHOOSE_A_COMPANY") }}</div>
        <div class="matchList-filter_buttons">
          <div
            v-for="o in handicapOptions.companyList"
            :key="o.id"
            class="nav-pill"
            :class="{ active: thisOdds.handicapOptions.company.id === o.id }"
            @click="$emit('selectCompany', o, 'handicapOptions')"
          >
            {{ $t(o.name) }}
          </div>
        </div>
      </div>

      <div class="matchList-filter_wrapper">
        <div class="matchList-filter_title">{{ $t("CHOOSE_A_COMPANY") }}</div>
        <div class="matchList-filter_buttons">
          <div
            v-for="o in oddsOptions.companyList"
            :key="o.id"
            class="nav-pill"
            :class="{ active: thisOdds.oddsOptions.company.id === o.id }"
            @click="$emit('selectCompany', o, 'oddsOptions')"
          >
            {{ $t(o.name) }}
          </div>
        </div>
      </div>

      <div class="matchList-filter_wrapper">
        <div class="matchList-filter_title">{{ $t("STATUS") }}</div>
        <div class="matchList-filter_buttons">
          <div
            v-for="o in matchDuration"
            :key="o.state"
            class="nav-pill"
            :class="{
              active:
                o.state === thisOdds.oddsOptions.duration.state &&
                thisOdds.handicapOptions.duration.state,
            }"
            @click="$emit('selectDuration', o)"
          >
            {{ $t(o.name) }}
          </div>
        </div>
      </div>

      <div class="matchList-filter_wrapper">
        <div class="matchList-filter_title">
          {{ $t("FULL_TIME") + "," + $t("HALF_TIME") }}
        </div>
        <div class="matchList-filter_buttons">
          <div
            v-for="o in matchPeriod"
            :key="o.state"
            class="nav-pill"
            :class="{ active: o.state === thisOdds.matchOptions.state }"
            @click="$emit('selectDuration', o, 'matchOptions')"
          >
            {{ $t(o.name) }}
          </div>
        </div>
      </div>
    </div>

    <div class="matchList-filter_confirm">
      <button @click="filterConfirmHandler">
        {{ $t("CONFIRM_SELECTION") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: [
    "thisOdds",
    "listOption",
    "lengthSelected",
    "oddsOptions",
    "handicapOptions",
    "matchDuration",
    "matchPeriod",
    "matchOptions",
    "leagueOption",
    "leagueList",
    "isSame",
  ],
  computed: {
    ...mapGetters(["homeOdds", "awayOdds", "h2hOdds"]),
  },
  methods: {
    ...mapActions(["isOddsMenuToggle"]),
    filterConfirmHandler() {
      //   let fullPath = this.$route.fullPath.split('/');

      this.$router.go(-1);
      this.isOddsMenuToggle();
    },
  },
};
</script>

<style scoped>
.matchList-content_overlay {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 4 !important;
}
.matchList-content_wrapper {
  margin: 3rem auto 7rem auto;
  width: 95%;
  flex-direction: column;
}
.matchList-filter_wrapper {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0.5rem 0;
}
.matchList-filter_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.625rem;
}
.nav-pill {
  padding: 0.375rem 0.75rem;
}
.active {
  background-color: var(--color-hover);
  border: 0.1rem solid var(--color-hover);
  color: black;
  font-weight: 700;
}
.matchList-filter_confirm {
  padding: 1.625rem 2rem;
  border-top: 2px solid #4b616c;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: #000;
}
.matchList-filter_confirm button {
  background: #01c3f7;
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #fff;
  padding: 0.5rem 0;
  width: 100%;
  cursor: pointer;
}
</style>
